.cls-1,
.cls-2,
.cls-3,
.cls-4,
.cls-5 {
    font-weight: 500;
}

.cls-1,
.cls-2,
.cls-6,
.cls-7,
.cls-8 {
    font-size: 24px;
}

.cls-9 {
    fill: #00831e;
}

.cls-10 {
    stroke-dasharray: 0 0 11.92 11.92;
}

.cls-10,
.cls-11,
.cls-12,
.cls-13,
.glowingLine,
.cls-15,
.cls-16,
.cls-17,
.cls-18,
.cls-19,
.cls-20,
.cls-21,
.cls-22,
.cls-23,
.cls-24 {
    stroke-miterlimit: 10;
}

.cls-10,
.cls-11,
.cls-12,
.cls-13,
.glowingLine,
.cls-15,
.cls-17,
.cls-18,
.cls-20,
.cls-21,
.cls-22 {
    fill: none;
}

.cls-10,
.cls-11,
.cls-12,
.cls-15,
.cls-20,
.cls-21,
.cls-22 {
    stroke: #ccc;
}

.cls-25 {
    letter-spacing: 0em;
}

.cls-26 {
    fill: #e51c19;
}

.cls-11,
.cls-12,
.glowingLine,
.cls-15,
.cls-20,
.cls-21 {
    stroke-width: 4px;
}

.cls-27,
.cls-28,
.cls-16,
.cls-23 {
    fill: #fff;
}

.cls-29 {
    letter-spacing: -.03em;
}

.cls-29,
.cls-30,
.cls-31 {
    fill: #4d4d4d;
}

.cls-29,
.cls-32,
.cls-33,
.cls-31 {
    font-size: 23.85px;
}

.cls-29,
.cls-32,
.cls-31 {
    font-family: Rubik-Light, Rubik;
    font-variation-settings: 'wght' 300;
    font-weight: 300;
}

.cls-2,
.cls-3,
.cls-5,
.cls-34,
.cls-35,
.cls-36 {
    font-family: DMSans-Medium, 'DM Sans';
}

.cls-37 {
    letter-spacing: -.05em;
}

.cls-38 {
    letter-spacing: 0em;
}

.cls-39 {
    letter-spacing: 0em;
}

.cls-12 {
    stroke-dasharray: 0 0 12.88 12.88;
}

.cls-40 {
    letter-spacing: -.02em;
}

.cls-41,
.cls-42,
.cls-28 {
    font-family: DMSans-Bold, 'DM Sans';
    font-weight: 700;
}

.cls-41,
.cls-43 {
    fill: #12b886;
}

.cls-41,
.cls-44 {
    font-size: 18px;
}

.cls-45 {
    letter-spacing: -.05em;
}

.cls-46,
.cls-6,
.cls-7 {
    font-family: RubikRoman-Medium, Rubik;
    font-variation-settings: 'wght' 500;
}

.cls-46,
.cls-7 {
    fill: #37b24d;
}

.cls-47 {
    letter-spacing: -.09em;
}

.cls-48 {
    fill: #d3f9d8;
}

.cls-49 {
    letter-spacing: -.07em;
}

.cls-3,
.cls-35,
.cls-36 {
    font-size: 36px;
}

.cls-28,
.cls-5,
.cls-50,
.cls-51 {
    font-size: 14px;
}

.cls-52 {
    isolation: isolate;
}

.cls-53 {
    letter-spacing: -.01em;
}

.cls-54 {
    letter-spacing: -.01em;
}

.cls-55 {
    letter-spacing: 0em;
}

.cls-56 {
    letter-spacing: -.03em;
}

.cls-57 {
    letter-spacing: -.04em;
}

.cls-58 {
    fill: #00a94b;
}

.cls-5 {
    fill: #7d7d7d;
}

.cls-59 {
    letter-spacing: -.01em;
}

.cls-60,
.cls-51,
.cls-44,
.cls-8 {
    font-family: DMSans-Regular, 'DM Sans';
}

.cls-61 {
    letter-spacing: 0em;
}

.cls-62 {
    letter-spacing: -.03em;
}

.cls-63 {
    fill: #ff4131;
    fill-rule: evenodd;
}

.cls-64 {
    letter-spacing: 0em;
}

.cls-65 {
    letter-spacing: -.01em;
}

.cls-13 {
    stroke: #4d4d4d;
}

.cls-13,
.cls-17,
.cls-18 {
    stroke-width: 2px;
}

.glowingLine {
    stroke: #fcc419;
}

.cls-66 {
    letter-spacing: -.02em;
}

.cls-67 {
    letter-spacing: -.05em;
}

.cls-68 {
    letter-spacing: -.03em;
}

.cls-69 {
    fill: #e6e6e6;
}

.cls-15 {
    stroke-dasharray: 0 0 12.54 12.54;
}

.cls-70 {
    fill: #0085f7;
}

.cls-34,
.cls-35 {
    fill: #a1a1a1;
}

.cls-16,
.cls-19,
.cls-24 {
    stroke: #e6e6e6;
}

.cls-71 {
    letter-spacing: -.01em;
}

.cls-72 {
    letter-spacing: 0em;
}

.cls-73 {
    letter-spacing: 0em;
}

.cls-17 {
    stroke: #858585;
}

.cls-18 {
    stroke: #37b24d;
}

.cls-19 {
    fill: #fbe7c9;
}

.cls-74 {
    letter-spacing: -.02em;
}

.cls-75 {
    letter-spacing: -.02em;
}

.cls-76 {
    letter-spacing: -.04em;
}

.cls-77 {
    mix-blend-mode: multiply;
    opacity: .19;
}

.cls-50,
.cls-51 {
    fill: #5c5c5c;
}

.cls-78 {
    letter-spacing: -.09em;
}

.cls-79 {
    letter-spacing: -.04em;
}

.cls-80 {
    fill: #fbffff;
}

.cls-81 {
    letter-spacing: -.02em;
}

.cls-82 {
    letter-spacing: -.01em;
}

.cls-83 {
    letter-spacing: 0em;
}

.cls-84 {
    letter-spacing: 0em;
}

.cls-20 {
    stroke-dasharray: 0 0 11.9 11.9;
}

.cls-85 {
    letter-spacing: 0em;
}

.cls-21 {
    stroke-dasharray: 0 0 11.74 11.74;
}

.cls-86 {
    letter-spacing: -.02em;
}

.cls-87 {
    fill: #00ad3c;
}

.cls-36 {
    fill: gray;
}

.cls-33 {
    font-family: RubikRoman-SemiBold, Rubik;
    font-variation-settings: 'wght' 600;
    font-weight: 600;
}

.cls-23 {
    stroke: #999;
}

.cls-88 {
    fill: url(#linear-gradient);
}

.cls-89 {
    letter-spacing: -.02em;
}

.cls-90 {
    letter-spacing: -.07em;
}

.cls-91 {
    letter-spacing: -.01em;
}

.cls-92 {
    fill: #ffbc00;
}

.cls-93 {
    letter-spacing: -.01em;
}

.cls-94 {
    letter-spacing: -.02em;
}

.cls-95 {
    letter-spacing: -.02em;
}

.cls-8 {
    fill: #383838;
}

.cls-24 {
    fill: #d8ffe2;
}

.cls-96 {
    letter-spacing: -.02em;
}

.cls-97 {
    fill: #e3e3e3;
}

.cls-98 {
    letter-spacing: -.09em;
}


/* Defined by me */

/* .glowingLine {
    stroke: #19fc8a;
} */